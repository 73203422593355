<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>


		<div v-if="!loadingData">

            <a-row :gutter="24" class="" style="margin-top: 0px">
                <a-col :span="24">
                    <EditTutorProfile v-if="userDetails.role != null && userDetails.role.code == 'teacher'"/>
                    <EditStudentProfile v-if="userDetails.role != null && userDetails.role.code == 'student'"/>
					<EditInstituteProfile v-if="userDetails.role != null && userDetails.role.code == 'admin' && userDetails.role.category == 'institute'"/>
                </a-col>
            </a-row>
		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>

	</div>
</template>

<script>
    import VueJwtDecode from 'vue-jwt-decode'
    import EditStudentProfile from './EditStudentProfile.vue'
    import EditTutorProfile from './EditTutorProfile.vue'
    import EditInstituteProfile from './EditInstituteProfile.vue'
	import BreadCrumb from '../../components/Headers/BreadCrumbs.vue'

	export default ({

        components: {
            EditTutorProfile,
            EditStudentProfile,
			EditInstituteProfile,
			BreadCrumb
        },
		

		data() {
			return {

				loadingData: false,

				userDetails: {},
      		}
		},
		mounted () {
			let breadcrumbs = [
				{ title: 'Profile', url: '/profile', isActive: false },
                { title: 'Edit', url: '', isActive: true }
            ];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
            this.getUserDetails();
		},
		methods: {


            async getUserDetails() {

                this.loadingData = true;

				let userInfo = await localStorage.getItem("user_details")

				if(userInfo != null) {
                    this.userDetails = JSON.parse(userInfo);

                    this.loadingData = false;
				} else {
                    this.loadingData = false;
                }

			},
         

		},
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

a:hover {
    color: inherit !important;
}

#classImg {
	/* border-radius: 10px; */
}

div.ant-select-selection, .ant-select-selection--single {
	border: none !important;
}


div.scrollmenu {
  /* background-color: #333; */
  overflow: auto;
  white-space: nowrap;
  /* overflow: hidden; */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
}

div.scrollmenu > div {
  display: inline-block;
  color: white;
  /* text-align: center; */
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #777;
}
</style>